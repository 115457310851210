<div
  class="d-flex flex-column height-100vh"
  [ngClass]="!currentAccount?.customization?.whitelabel && warningStatusComponents?.length ? 'main-with-warning' : ''"
>
  <div class="sticky-top">

    <nav
      class="header-navbar navbar navbar-dark bg-primary navbar-expand-lg"
      [ngClass]="{scrolled: isScrolled}"
    >

      <ng-template #userDropdown>
        <h6 class="d-none d-lg-block dropdown-header">
          <span>{{user.username}}</span>
        </h6>
        <div class="d-flex">
          <div
            *ngIf="user.permissions[0]?.accountId !== currentAccount.id || ('switch-account' | permission)"
            (click)="$event.stopPropagation()"
          >
            <h5 class="dropdown-header ">{{'label.currentAccount' | translate}}: {{currentAccount.name}}</h5>
            <div
              *ngIf="('switch-account' | permission) && availableAccounts"
              class="form-group dropdown-header account-selector-form-group"
            >
              <label
                class="d-block"
                for="selectedAccount"
              >{{'label.switchAccount' | translate}}</label>
              <ng-template
                #accountSearchTemplate
                let-r="result"
                let-t="term"
              >
                <ngb-highlight
                  [result]="r.name"
                  [term]="t"
                  id="topmenu-userchangeaccountitem"
                ></ngb-highlight>
              </ng-template>
              <input
                type="text"
                class="form-control form-control-sm"
                name="selectedAccount"
                id="selectedAccount"
                [(ngModel)]="selectedAccount"
                [ngbTypeahead]="searchAccount"
                [editable]="false"
                [resultFormatter]="accountResultFormatter"
                [inputFormatter]="accountInputFormatter"
                (focus)="onSearchAccountFocus($event)"
                (selectItem)="onSelectedAccountChange($event)"
              />
            </div>
            <button
              *ngIf="user.permissions[0]?.accountId !== currentAccount.id"
              type="button"
              class="dropdown-item cursor-pointer"
              (click)="resetSelectedAccount()"
              id="topmenu-userbackrootaccount"
            >
              <span class="material-symbols-filled">undo</span>
              <span
                class="ml-1"
                id="topmenu-userbackrootaccount"
              >Back to your root account</span>
            </button>
          </div>
          <div>
            <a
              class="dropdown-item"
              [routerLink]="['/customers/users/user', user.getId()]"
              routerLinkActive="active"
              *ngIf="'edit-me' | permission"
              id="topmenu-usersettings"
            >
              <span
                class="material-symbols-filled"
                id="topmenu-usersettings"
              >settings</span>
              <span
                class="ml-1"
                id="topmenu-usersettings"
              >{{ 'section.userSettings' | translate}}</span>
            </a>
            <a
              class="dropdown-item"
              routerLink="/login"
              *ngIf="'logout' | permission"
              id="topmenu-userlogout"
            >
              <span
                class="material-symbols-filled"
                id="topmenu-userlogout"
              >logout</span>
              <span
                class="ml-1"
                id="topmenu-userlogout"
              >{{ 'label.logout' | translate}}</span>
            </a>
          </div>
        </div>
      </ng-template>

      <div class="container">

        <h1 class="navbar-brand mr-auto">
          <a
            class="d-flex align-items-center"
            routerLink="/"
          >
            <span class="mr-3">
              <img
                id="logo-large"
                [src]="currentAccount?.customization?.logoLarge | fileUrl"
                class="img-fluid"
              />
            </span>
            <span>IoT Suite</span>
          </a>
        </h1>

        <!-- Notifications/user for small devices -->
        <div class="navbar-not-collapse d-lg-none">
          <ul class="flex-row navbar-nav mr-2">
            <li
              class="nav-item"
              routerLinkActive="active"
            >
              <a
                class="nav-link nav-link-button"
                routerLink="/notifications"
              >
                <span
                  class="nav-link-icon big"
                  id="topmenu-notificationscollapsed"
                >
                  <span
                    class="material-symbols-filled"
                    id="topmenu-notificationscollapsed"
                  >notifications</span>
                </span>
                <span
                  class="nav-link-badge badge badge-pill"
                  [ngClass]="[unreadNotifications?.$totalCount ? 'badge-danger' : 'badge-dark']"
                  id="topmenu-notificationscollapsed"
                >{{
                  unreadNotifications?.$totalCount || 0 }}</span>
              </a>
            </li>
            <li
              class="nav-item navbar-item-user"
              [ngClass]="{'switched': user.permissions[0]?.accountId !== currentAccount.id}"
            >
              <span
                ngbDropdown
                display="dynamic"
                placement="bottom-right"
                class="nav-link"
              >
                <button
                  ngbDropdownToggle
                  id="topmenu-usercollapsed"
                >
                  <span
                    class="nav-link-button"
                    id="topmenu-usercollapsed"
                  >
                    <span
                      class="nav-link-icon"
                      id="topmenu-usercollapsed"
                    >
                      <span
                        class="material-symbols-filled"
                        id="topmenu-usercollapsed"
                      >person</span>
                    </span>
                  </span>
                </button>
                <span
                  ngbDropdownMenu
                  class="section-menu"
                  id="topmenu-usercollapsed"
                >
                  <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
                </span>
              </span>
            </li>
          </ul>
        </div>

        <button
          class="navbar-toggler"
          type="button"
          (click)="isNavbarHeaderCollapsed = !isNavbarHeaderCollapsed"
          id="opencollapsedmenu"
        >
          <span
            class="navbar-toggler-icon"
            id="opencollapsedmenu"
          ></span>
        </button>

        <div
          class="navbar-collapse justify-content-end"
          [ngbCollapse]="isNavbarHeaderCollapsed"
        >

          <ul class="navbar-nav mr-1">
            <li
              *ngFor="let mh of filteredMenu"
              class="nav-item"
              routerLinkActive="active"
            >
              <a
                *ngIf="!mh.children"
                class="nav-link nav-link-button"
                [routerLink]="mh.routerLink"
                [attr.id]="mh.id"
              >
                <span
                  class="nav-link-icon"
                  [attr.id]="mh.id"
                >
                  <span class="material-symbols-filled">{{mh.icon}}</span>
                </span>
                <span
                  class="nav-link-label"
                  [attr.id]="mh.id"
                >{{mh.label | translate}}</span>
              </a>
              <span
                *ngIf="mh.children"
                ngbDropdown
                display="dynamic"
                placement="bottom"
                class="nav-link"
              >
                <button
                  ngbDropdownToggle
                  [attr.id]="mh.id"
                >
                  <span
                    class="nav-link-button"
                    [attr.id]="mh.id"
                  >
                    <span
                      class="nav-link-icon"
                      [attr.id]="mh.id"
                    >
                      <span class="material-symbols-filled">{{mh.icon}}</span>
                    </span>
                    <span
                      class="nav-link-label"
                      [attr.id]="mh.id"
                    >{{mh.label | translate}}</span>
                  </span>
                </button>
                <span
                  ngbDropdownMenu
                  class="section-menu"
                >
                  <div class="d-flex">
                    <div>
                      <h6 class="d-none d-lg-block dropdown-header">
                        <span class="material-symbols-filled">{{mh.icon}}</span>
                        <span class="ml-1">{{mh.label | translate}}</span>
                      </h6>
                      <div class="ml-4">
                        <a
                          *ngFor="let child of mh.children"
                          class="dropdown-item"
                          [routerLink]="child.routerLink"
                          routerLinkActive="active"
                          [attr.id]="child.id"
                        >
                          <span [attr.id]="child.id">{{child.label | translate}}</span>
                        </a>
                      </div>
                    </div>
                    <div *ngIf="mh.quickLinks">
                      <h6 class="d-none d-lg-block dropdown-header">
                        <span class="material-symbols-filled">link</span>
                        <span class="ml-1">{{'label.quickLinks' | translate}}</span>
                      </h6>
                      <div class="ml-4">
                        <a
                          *ngFor="let ql of mh.quickLinks"
                          class="dropdown-item"
                          [routerLink]="ql.routerLink"
                          [attr.id]="ql.id"
                        >
                          <span [attr.id]="ql.id">{{ql.label | translate}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </span>
              </span>
            </li>
          </ul>

          <ul class="navbar-nav d-none d-lg-flex">
            <li
              *ngIf="'show-notifications' | permission"
              class="nav-item"
              routerLinkActive="active"
            >
              <span
                ngbDropdown
                display="dynamic"
                placement="bottom-right"
                class="nav-link"
              >
                <button ngbDropdownToggle>
                  <span class="nav-link-button">
                    <span class="nav-link-icon big">
                      <span class="material-symbols-filled">notifications</span>
                    </span>
                    <span class="d-lg-none nav-link-label mt-1">Notifications</span>
                    <span
                      class="nav-link-badge badge badge-pill ml-1"
                      [ngClass]="[unreadNotifications?.$totalCount ? 'badge-danger' : 'badge-dark']"
                    >{{
                      unreadNotifications?.$totalCount || 0 }}</span>
                  </span>
                </button>
                <span
                  ngbDropdownMenu
                  class="notifications-menu"
                >
                  <h5 class="dropdown-header text-center">
                    <span *ngIf="unreadNotifications?.$totalCount && ('read-notifications' | permission)">{{
                      'message.youHaveXUnreadNotifications' | translate:{count:unreadNotifications?.$totalCount}
                      }}</span>
                    <span *ngIf="!unreadNotifications?.$totalCount && ('read-notifications' | permission)">{{
                      'message.youHaveNoUnreadNotifications' | translate }}</span>
                  </h5>
                  <a
                    class="dropdown-item text-center mb-3"
                    routerLink="/notifications"
                  >
                    <span class="material-symbols-outlined">notifications</span>
                    <span
                      class="ml-2"
                      id="topmenu-notificationcenter"
                    >{{ 'section.notificationCenter' | translate}}</span>
                  </a>
                  <span
                    *ngFor="let notification of unreadNotifications"
                    class="notification-item d-flex align-items-center p-2 mx-3 cursor-pointer"
                    [ngClass]="notification.result === NotificationResult.ERROR ? 'alert-danger' : ''"
                    (click)="setNotificationRead(notification)"
                  >
                    <span class="text-primary notification-item-icon mr-3">
                      <span class="material-symbols-outlined">mail</span>
                    </span>
                    <span
                      class="notification-item-text"
                      id="topmenu-notificationitem"
                    >{{getNotificationText(notification)}}</span>
                  </span>
                  <a
                    *ngIf="unreadNotifications && unreadNotifications.$totalCount > unreadNotifications.length"
                    class="dropdown-item text-center mt-3"
                    routerLink="/notifications"
                  >
                    <span class="material-symbols-outlined">notifications</span>
                    <span class="ml-2">{{ 'message.countMoreUnreadNotifications' | translate:{count:
                      unreadNotifications.$totalCount -
                      unreadNotifications.length} }}</span>
                  </a>
                </span>
              </span>
            </li>
            <li
              class="nav-item navbar-item-user"
              [ngClass]="{'switched': user.permissions[0]?.accountId !== currentAccount.id}"
            >
              <span
                ngbDropdown
                display="dynamic"
                placement="bottom-right"
                class="nav-link"
              >
                <button
                  ngbDropdownToggle
                  class="nav-link d-flex align-items-center"
                >
                  <span class="nav-link-button d-lg-flex align-items-center">
                    <span class="nav-link-icon big mr-2">
                      <span
                        class="material-symbols-filled"
                        id="topmenu-user"
                      >person</span>
                    </span>
                    <span class="d-flex flex-column nav-link-label-2-lines nav-link-content-max-width">
                      <span
                        class="text-truncate"
                        id="topmenu-user"
                        [title]="user.username"
                      >{{user.username}}</span>
                      <small
                        class="text-truncate"
                        id="topmenu-user"
                        [title]="currentAccount.name"
                      >{{currentAccount.name}}</small>
                    </span>
                  </span>
                </button>
                <span
                  ngbDropdownMenu
                  class="section-menu"
                >
                  <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
                </span>
              </span>
            </li>
          </ul>

        </div>

      </div>

    </nav>

    <div
      *ngIf="!currentAccount?.customization?.whitelabel && warningStatusComponents?.length"
      class="header-status text-center my-0 rounded-0 border-0 alert"
      [ngClass]="statusStyle[statusLevel].alertClass"
    >
      <div class="container">
        <span>{{statusStyle[statusLevel].alertText | translate}}&nbsp;</span>
        <a
          class="alert-link"
          id="performanceissues"
          [href]="environment.statusPageUrl"
          target="_blank"
          id="alert-link"
        >{{'message.clickHereToFindOutMore' | translate}}</a>
      </div>
    </div>

  </div>

  <div class="container flex-fill">
    <router-outlet></router-outlet>
  </div>

  <nav class="footer-navbar navbar justify-content-center mt-5">
    <span class="navbar-text">
      <span *ngIf="!currentAccount?.customization?.whitelabel">
        <span>{{'message.copyright' | translate}} {{ currentYear }}</span>
        <span class="ml-2">{{'label.gieseckeDevrientGmbh' | translate}}</span>.
        <span class="ml-2">{{'message.allRightsReserved' | translate}}</span>
      </span>
      <span class="ml-3">
        <a
          *ngIf="!currentAccount?.customization?.whitelabel"
          class="px-3"
          id="footer-privacylink"
          href="https://www.gi-de.com/en/data-privacy"
          target="_blank"
        >{{'label.privacyTerms' | translate}}</a>
        <a
          class="px-3"
          id="footer-supportlink"
          routerLink="/support"
        >{{'label.contact' | translate}}</a>
        <a
          class="px-3"
          id="footer-apilink"
          [href]="!currentAccount?.customization?.whitelabel ? (environment.apiHost + '/docs/swagger/index.html') : 'https://apicontrolcenter.com/v3/docs/swagger/index.html'"
          target="_blank"
        >{{'label.API' | translate}}</a>
        <a
          *ngIf="!currentAccount?.customization?.whitelabel"
          class="px-3"
          id="footer-statuslink"
          [href]="environment.statusPageUrl"
          target="_blank"
        >
          <span
            *ngIf="warningStatusComponents"
            class="circle-indicator"
            [ngClass]="statusStyle[statusLevel].indicatorClass"
          ></span>
          {{statusStyle[statusLevel].indicatorText | translate}}
        </a>
        <a
          class="px-3"
          id="footer-platformlink"
          href="https://platformhowto.com/"
          target="_blank"
        >{{'label.knowledgeBase' | translate}}</a>
      </span>
    </span>

  </nav>
</div>