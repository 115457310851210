import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  NgbModal,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  Account,
  AccountType,
  ApiList,
  ApiService,
  FileSizePipe,
  IAccountTreeItem,
  Notification,
  NotificationResult,
  SessionService,
  User,
  TermsAndConditions
} from 'pod-ng-core';
import { Observable, Subscription, combineLatest, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TermsAndConditionsModal } from 'src/components/modals/terms-and-conditions/terms-and-conditions.modal';
import { CampaignType } from 'src/models/campaign.class';
import {
  StatusComponent,
  StatusComponentStatus,
} from 'src/models/statusComponent.class';
import { SocketService } from 'src/services/socket.service';
import { ThemeService } from 'src/services/theme.service';
import { environment } from '../../environments/environment';

export interface IMenuItem {
  icon: string;
  routerLink: string;
  label: string;
  show: boolean | Function;
  children?: Array<IMenuItem>;
  quickLinks?: Array<IMenuItem>;
  id: string;
}

interface IAccountTree {
  id: string;
  name: string;
  levelIndicator: string;
}

const CURRENT_APP_VERSION = 'V3-1-5';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, OnDestroy {
  public currentYear = new Date().getFullYear();
  private navigationStartSub: Subscription;
  private navigationEndSub: Subscription;
  private unreadNotificationsSub: any;

  private menu: Array<IMenuItem> = [
    {
      icon: 'dashboard',
      routerLink: '/dashboard',
      label: 'section.dashboard',
      show: true,
      id: 'topmenu-dashboard',
    },
    {
      icon: 'groups_3',
      id: 'topmenu-customers',
      routerLink: '/customers',
      label: 'section.customers',
      show:
        this.session.hasPermission('show-accounts') ||
        this.session.hasPermission('show-user') ||
        this.session.hasPermission('create-account') ||
        this.session.hasPermission('creat-user'),
      children: [
        {
          icon: 'id-card',
          id: 'topmenu-customersaccounts',
          routerLink: '/customers/accounts',
          label: 'section.allAccounts',
          show: this.session.hasPermission('show-accounts'),
        },
        {
          icon: 'user',
          id: 'topmenu-customersusers',
          routerLink: '/customers/users',
          label: 'section.allUsers',
          show: this.session.hasPermission('show-user'),
        },
      ],
      quickLinks: [
        {
          icon: 'plus',
          id: 'topmenu-customersaddaccount',
          routerLink: '/customers/accounts/add-account',
          label: 'section.addAccount',
          show: this.session.hasPermission('create-account'),
        },
        {
          icon: 'plus',
          id: 'topmenu-customersadduser',
          routerLink: '/customers/users/add-user',
          label: 'section.addUser',
          show: this.session.hasPermission('create-user'),
        },
      ],
    },
    {
      icon: 'auto_awesome_motion',
      id: 'topmenu-subscriptions',
      routerLink: '/manage-subscriptions',
      label: 'section.subscriptions',
      show:
        this.session.hasPermission('order-sim') ||
        this.session.hasPermission('list-assets') ||
        this.session.hasPermission('transfer-asset') ||
        this.session.hasPermission('transfer-products') ||
        this.session.hasPermission('list-products') ||
        this.session.hasPermission('show-additional-products'),
      children: [
        {
          icon: 'signal',
          id: 'topmenu-subscriptionssims',
          routerLink: '/manage-subscriptions/sims',
          label: 'section.allSims',
          show: this.session.hasPermission('list-assets'),
        },
        {
          icon: 'signal',
          id: 'topmenu-subscriptionsesims',
          routerLink: '/manage-subscriptions/esims',
          label: 'section.eSimManagement',
          show: this.session.hasPermission('list-esims'),
        },
        {
          icon: 'signal',
          id: 'topmenu-administrationesims',
          routerLink: '/manage-subscriptions/rsp-instances',
          label: 'section.eSimAdministration',
          show: this.session.hasPermission('list-rspinstances'),
        },
        {
          icon: 'signal',
          id: 'topmenu-subscriptionsimsis',
          routerLink: '/manage-subscriptions/imsis',
          label: 'section.imsiManagement',
          show: this.session.hasPermission('list-imsis'),
        },
        {
          icon: 'list',
          id: 'topmenu-subscriptionsstock',
          routerLink: '/manage-subscriptions/stock',
          label: 'section.simStock',
          show:
            this.session.hasPermission('transfer-asset') ||
            this.session.hasPermission('transfer-products'),
        },
        {
          icon: 'plus',
          id: 'topmenu-subscriptionsorder-sims',
          routerLink: '/manage-subscriptions/order-sims',
          label: 'section.orderSims',
          show: this.session.hasPermission('order-sim'),
        },
        {
          icon: 'bar-chart',
          id: 'topmenu-subscriptionsproducts',
          routerLink: '/manage-subscriptions/products',
          label: 'section.connectivityProducts',
          show: this.session.hasPermission('list-products'),
        },
        {
          icon: 'bar-chart',
          id: 'topmenu-subscriptionsaddtinal-products',
          routerLink: '/manage-subscriptions/additional-products',
          label: 'section.additionalProducts',
          show: this.session.hasPermission('show-additional-products'),
        },
        {
          icon: 'bar-chart',
          id: 'topmenu-subscriptionscommercial-references',
          routerLink: '/manage-subscriptions/commercial-references',
          label: 'section.commercialReferences',
          show: this.session.hasPermission('manage-gd-billing'),
        },
        {
          icon: 'bar-chart',
          id: 'topmenu-subscriptionsmaterial',
          routerLink: '/manage-subscriptions/material',
          label: 'section.materials',
          show: this.session.hasPermission('manage-gd-billing'),
        },
      ],
    },
    {
      icon: 'feed',
      id: 'topmenu-reports',
      routerLink: '/reports',
      label: 'section.reports',
      show:
        this.session.hasPermission('get-cdrs') ||
        this.session.hasPermission('get-cdrs-stats') ||
        this.session.hasPermission('list-custom-reports') ||
        this.session.hasPermission('show-my-billing-report') ||
        this.session.hasPermission('list-esims'),
      children: [
        {
          icon: 'line-chart',
          id: 'topmenu-reportssession-usage',
          routerLink: '/reports/session-usage',
          label: 'section.sessionUsage',
          show:
            this.session.hasPermission('get-cdrs') ||
            this.session.hasPermission('get-cdrs-stats'),
        },
        {
          icon: 'eye',
          id: 'topmenu-reportssummary',
          routerLink: '/reports/summary',
          label: 'section.summary',
          show: this.session.hasPermission('list-custom-reports'),
        },
        {
          icon: 'dollar',
          id: 'topmenu-reportsbilling',
          routerLink: '/reports/billing',
          label: 'section.billingReports',
          show: this.session.hasPermission('show-my-billing-report'),
        },
        {
          icon: 'line-chart',
          id: 'topmenu-reportsesim',
          routerLink: '/reports/esim-operations',
          label: 'section.esimOperations',
          show: this.session.hasPermission('list-esims'),
        },
      ],
    },
    {
      icon: 'devices',
      id: 'topmenu-devices',
      routerLink: '/devices',
      label: 'section.devices',
      show:
        this.session.hasPermission('show-device') ||
        this.session.hasPermission('show-device-provisioning'),
      children: [
        {
          icon: 'microchip',
          id: 'topmenu-devicesAll',
          routerLink: '/devices/all-devices',
          label: 'section.allDevices',
          show: this.session.hasPermission('show-device'),
        },
        {
          icon: 'microchip',
          id: 'topmenu-devicesztp',
          routerLink: '/devices/ztp',
          label: 'section.zeroTouchProvisioning',
          show: this.session.hasPermission('show-device-provisioning'),
        },
      ],
    },
    {
      icon: 'rocket_launch',
      id: 'topmenu-campaigns',
      routerLink: '/campaigns',
      label: 'section.campaigns',
      show: Object.values(CampaignType).some(
        type =>
          this.session.hasPermission('create-campaign-' + type) ||
          this.session.hasPermission('list-campaigns-' + type)
      ),
      children: [
        {
          icon: 'rocket',
          id: 'topmenu-campaignsdashboard',
          routerLink: '/campaigns/dashboard',
          label: 'section.dashboard',
          show: Object.values(CampaignType).some(type =>
            this.session.hasPermission('list-campaigns-' + type)
          ),
        },
        {
          icon: 'plus',
          id: 'topmenu-campaignsplanner',
          routerLink: '/campaigns/planner',
          label: 'section.planner',
          show: Object.values(CampaignType).some(type =>
            this.session.hasPermission('create-campaign-' + type)
          ),
        },
      ],
    },
    {
      icon: 'payments',
      id: 'topmenu-billing',
      routerLink: '/billing',
      label: 'section.billing',
      show: () => {
        return (
          this.session.account.type === AccountType.MASTER ||
          this.session.hasPermission('list-billing-reports')
        );
      },
    },
    {
      icon: 'payments',
      id: 'topmenu-gd-billing',
      routerLink: '/gd-billing',
      label: 'section.gdBilling',
      show: this.session.hasPermission('manage-gd-billing'),
      children: [
        {
          icon: 'id-card',
          id: 'topmenu-gd-billing',
          routerLink: '/gd-billing/commercial-references-report',
          label: 'section.billing',
          show: this.session.hasPermission('manage-gd-billing'),
        },
        {
          icon: 'user',
          id: 'topmenu-gd-billing',
          routerLink: '/gd-billing/events-usage-summary',
          label: 'section.eventsSummaryUsage',
          show: this.session.hasPermission('manage-gd-billing'),
        },
      ],
    },
    {
      icon: 'settings',
      id: 'topmenu-settings',
      routerLink: '/settings',
      label: 'section.settings',
      show: true,
    },
    {
      icon: 'support',
      id: 'topmenu-suppport',
      routerLink: '/support',
      label: 'section.support',
      show: true,
    },
  ];
  public filteredMenu: Array<IMenuItem>;
  public isScrolled = false;
  public isNavbarHeaderCollapsed = true;

  public user: User;
  public unreadNotifications: ApiList<Notification>;
  public maxListedNotifications = 5;

  public currentAccount: Account;
  public selectedAccount: Account = null;
  public availableAccounts: IAccountTree[];

  public warningStatusComponents: Array<StatusComponent>;
  public statusLevel: StatusComponentStatus = StatusComponentStatus.OPERATIONAL;
  public readonly statusStyle: Record<
    StatusComponentStatus,
    {
      indicatorClass: string;
      indicatorText: string;
      alertClass?: string;
      alertText?: string;
    }
  > = {
    [StatusComponentStatus.OPERATIONAL]: {
      indicatorClass: 'circle-indicator-active',
      indicatorText: 'label.statusPage',
    },
    [StatusComponentStatus.PERFORMANCE_ISSUES]: {
      indicatorClass: 'circle-indicator-suspended',
      indicatorText: 'label.performanceIssues',
      alertClass: 'alert-warning',
      alertText: 'message.weAreExperiencingPerformanceIssues',
    },
    [StatusComponentStatus.PARTIAL_OUTAGE]: {
      indicatorClass: 'circle-indicator-suspended',
      indicatorText: 'label.partialOutage',
      alertClass: 'alert-warning',
      alertText: 'message.thereIsPartialOutageOnOurNetwork',
    },
    [StatusComponentStatus.MAJOR_OUTAGE]: {
      indicatorClass: 'circle-indicator-inactive',
      indicatorText: 'label.majorOutage',
      alertClass: 'alert-danger',
      alertText: 'message.thereIsMajorOutageOnOurNetwork',
    },
  };

  public NotificationResult = NotificationResult;
  public environment = environment;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private apiService: ApiService,
    private themeService: ThemeService,
    private session: SessionService,
    private socketService: SocketService,
    private fileSizePipe: FileSizePipe,
    private ngbModal: NgbModal
  ) {
    const customization: any = { ...environment.customization };
    if (this.session.account && this.session.account.customization) {
      Object.assign(customization, this.session.account.customization);
    }
    this.themeService.setTitle(customization.pageTitle);

    this.searchAccount = this.searchAccount.bind(this);
    this.getUnreadNotifications = this.getUnreadNotifications.bind(this);
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll($event) {
    this.isScrolled =
      (document.documentElement.scrollTop || document.body.scrollTop) > 10;
  }

  public ngOnInit() {
    const url = this.router.url;
    const lastLocation = this.session.getLastLocation();
    if (url === '/' || url === '/start') {
      this.router.navigate([lastLocation || '/dashboard'], {
        replaceUrl: true,
      });
    }

    this.socketService.init();

    // Filter menu
    this.filteredMenu = [...this.menu].filter(item => {
      const show = typeof item.show === 'function' ? item.show() : item.show;

      if (show) {
        if (item.children) {
          item.children = item.children.filter(child =>
            typeof child.show === 'function' ? child.show() : child.show
          );
        }
        if (item.quickLinks) {
          item.quickLinks = item.quickLinks.filter(quickLink =>
            typeof quickLink.show === 'function'
              ? quickLink.show()
              : quickLink.show
          );
        }
        return true;
      }
      return false;
    });

    // Get account an user from ApiService
    this.currentAccount = this.session.account;
    this.user = this.session.user;

    // Get accounts tree
    this.getAvailableAccounts().subscribe(
      accounts => (this.availableAccounts = accounts)
    );

    // Close mobile header menu when go to another page
    this.navigationStartSub = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.isNavbarHeaderCollapsed = true;
      });

    // Save location when navigation Ends
    this.navigationEndSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) =>
        this.session.saveLastLocation(event)
      );

    // Subscribe to getUnreadNotifications, and refresh in each navigation
    if (this.session.hasPermission('show-notifications')) {
      this.unreadNotificationsSub = this.getUnreadNotifications().subscribe(
        notifications => {
          this.unreadNotifications = <ApiList<Notification>>(
            notifications.slice(0, this.maxListedNotifications)
          );
          this.unreadNotifications.$totalCount = notifications.$totalCount;
        }
      );
      this.socketService.onAccount(
        'notifications',
        this.getUnreadNotifications
      );
    }

    // Get status if not white-labeled
    if (
      this.currentAccount.customization &&
      !this.currentAccount.customization.whitelabel
    ) {
      this.getStatusComponents();
      this.socketService.on('status', 'updated', () => {
        this.getStatusComponents();
      });
    }

    // ask T & C
    const tcAcceptedTime =
      this.session.user.termsAndConditionsAccepted.time;
    const tcAcceptedVersion =
      this.session.user.termsAndConditionsAccepted.version;
    const tcAcceptedLocation =
      this.session.user.termsAndConditionsAccepted.location;
    const tcAcceptedLink =
      this.session.user.termsAndConditionsAccepted.link;

    const isTermsAndConditionsAccepted =
      !!tcAcceptedTime &&
      !!tcAcceptedVersion &&
      !!tcAcceptedLocation &&
      !!tcAcceptedLink;

    const parentIsPod =
      this.session.isPodMasterAccount() ||
      this.session.isPodMasterAccountChild();

    if (
      this.session.user.permissions[0]?.accountId === this.session.account.id
    ) {
      combineLatest([
        of(
          parentIsPod &&
            !this.session.account.customization?.whitelabel &&
            !isTermsAndConditionsAccepted
        ),
        this.checkForUpdatedTermsAndConditions(
          tcAcceptedLocation,
          tcAcceptedLink,
          tcAcceptedVersion
        ),
        this.checkForParentAccountLocation(tcAcceptedLocation),
      ])
        .pipe(
          filter(values => values.some(value => value == true)),
          switchMap(() => {
            return this.askAcceptTermsAndCondition();
          })
        )
        .subscribe(isAccepted => {
          if (!isAccepted) {
            this.session.logout();
            window.location.assign('/');
            return;
          }
        });
    }
  }

  public checkForParentAccountLocation(acceptedLocation) {
    return this.apiService
      .getItem(Account, this.session.user.permissions[0]?.accountId)
      .pipe(
        map(parentAccount => {
          if (!!parentAccount.meta.location) {
            return acceptedLocation !== parentAccount.meta.location;
          }

          return false;
        })
      );
  }

  public checkForUpdatedTermsAndConditions(
    acceptedLocation,
    acceptedLink,
    acceptedVersion
  ) {
    return this.apiService
      .getList<TermsAndConditions>(TermsAndConditions, {
        accountId: this.session.user.permissions[0]?.accountId,
      })
      .pipe(
        map(tcList => {
          const tc = tcList.find(value => value.location === acceptedLocation);
          if (!tc) {
            return false;
          }

          return (
            tc.link !== acceptedLink || `${tc.version}` !== acceptedVersion
          );
        })
      );
  }

  public ngOnDestroy() {
    this.socketService.destroy();

    if (this.navigationStartSub) {
      this.navigationStartSub.unsubscribe();
    }

    if (this.navigationEndSub) {
      this.navigationEndSub.unsubscribe();
    }

    if (this.unreadNotificationsSub) {
      this.unreadNotificationsSub.unsubscribe();
    }

    this.socketService.offAccount('notifications', this.getUnreadNotifications);
  }

  private askAcceptTermsAndCondition(): Observable<boolean> {
    const modalRef = this.ngbModal.open(TermsAndConditionsModal, {
      backdrop: 'static',
    });

    return from(modalRef.result).pipe(
      switchMap(value =>
        value
          ? this.apiService
              .post(
                User,
                null,
                {
                  tcVersion: value.version,
                  tcLink: value.link,
                  tcLocation: value.location,
                },
                'me/accept-tc'
              )
              .pipe(mapTo(true))
          : of(false)
      ),
      catchError(() => of(false))
    );
  }

  private getUnreadNotifications() {
    return this.session.getUnreadNotifications(this.maxListedNotifications);
  }

  private getListOfAccountsTree(
    tree: IAccountTreeItem,
    level: number = 0
  ): IAccountTree[] {
    let result: IAccountTree[] = [
      {
        id: tree.id,
        name: tree.name,
        levelIndicator: level ? '>'.repeat(level) + ' ' : '',
      },
    ];

    (tree.children || []).forEach(child => {
      result = result.concat(this.getListOfAccountsTree(child, level + 1));
    });

    return result;
  }

  private getAvailableAccounts(): Observable<IAccountTree[]> {
    return this.session.getAllUserAccountsTrees().pipe(
      map(accountTrees => {
        let result = [];

        accountTrees.forEach((tree, index) => {
          result = result.concat(this.getListOfAccountsTree(tree));
        });

        return result;
      })
    );
  }

  public getNotificationText(notification: Notification): string {
    let result = this.translateService.instant(
      (notification.iccid
        ? 'notificationTypeMessageWithIccid'
        : 'notificationTypeMessage') +
        '.' +
        notification.type,
      {
        ...notification,
        currencySymbol: this.translateService.instant(
          'currencySymbol.' + this.currentAccount.currency
        ),
        limitInMb: this.fileSizePipe.transform(notification.limit),
      }
    );

    if (notification.result) {
      result +=
        ' ' +
        this.translateService.instant(
          'notificationsResult.' + notification.result
        );
    }

    return result;
  }

  public onSearchAccountFocus($event: Event) {
    $event.stopPropagation();
    setTimeout(() => {
      $event.target.dispatchEvent(new Event('input'));
    }, 0);
  }

  public searchAccount(text$: Observable<string>) {
    return text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map(term => {
        let result = this.availableAccounts || [];
        if (term) {
          const normalizedTerm = term.toUpperCase();
          result = result
            .filter(account =>
              account.name.toUpperCase().includes(normalizedTerm)
            )
            .sort((a, b) => {
              // Sort by index of the searched term
              const aName = a.name.toUpperCase();
              const bName = b.name.toUpperCase();
              const aIndex = aName.indexOf(normalizedTerm);
              const bIndex = bName.indexOf(normalizedTerm);
              if (aIndex === bIndex) {
                return aName.localeCompare(bName);
              }
              return aIndex - bIndex;
            });
        }

        return result.slice(0, 20); // Only show a few, as showing all results will slow down the browser
      })
    );
  }

  public accountResultFormatter(result: IAccountTree) {
    return result.levelIndicator + result.name;
  }

  public accountInputFormatter(result: IAccountTree) {
    return result.name;
  }

  public onSelectedAccountChange($event: NgbTypeaheadSelectItemEvent) {
    if (
      $event.item &&
      $event.item.id &&
      $event.item.id !== this.currentAccount.getId()
    ) {
      this.session
        .changeCurrentAccount($event.item.id)
        .subscribe(() => location.replace(location.origin));
    } else {
      setTimeout(() => {
        this.selectedAccount = null;
      }, 0);
    }
  }

  public resetSelectedAccount() {
    this.session.changeCurrentAccount(null).subscribe(() => location.reload());
  }

  public setNotificationRead(notification: Notification) {
    // Do not set as read when is eid operation
    if (notification.eid) {
      this.router.navigate(['/manage-subscriptions/esims/', notification.eid]);
    } else {
      this.session.setNotificationRead(notification, true).subscribe();
      if (notification.iccid) {
        this.router.navigate([
          '/manage-subscriptions/sims/',
          notification.iccid,
        ]);
      }
    }
  }

  public getStatusComponents() {
    this.apiService.getList(StatusComponent).subscribe({
      next: statuses => {
        this.warningStatusComponents = statuses.filter(
          status => status.statusLevel > 1
        );
        this.warningStatusComponents.sort(
          (a, b) => b.statusLevel - a.statusLevel
        );
        this.statusLevel = this.warningStatusComponents[0]
          ? this.warningStatusComponents[0].status
          : StatusComponentStatus.OPERATIONAL;
      },
      error: () => {
        this.warningStatusComponents = null;
        this.statusLevel = StatusComponentStatus.OPERATIONAL;
      },
    });
  }
}
