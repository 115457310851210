import { Serializable } from "./serializable.class";

export class TermsAndConditions extends Serializable {
  version: string;
  link: string;
  location: string;

  public getResourceName(): string {
    return 'termsAndConditions';
  }

  public getExportPath(): string {
    return this.getBulkPath().substring(0, this.getBulkPath().length - 4);
  }
}
