<div class="modal-header">
  <div class="modal-title">
    <h5>{{'label.termsAndConditions' | translate}}</h5>
    <p class="modal-title">
      {{'label.termsAndConditionsDescription' | translate}}
    </p>
    <p *ngIf="acceptedTermsAndConditions">
      <span class="font-weight-bolder"> {{'label.acceptedTermsAndConditions' | translate}} </span>

      {{'tcLocation.' + acceptedTermsAndConditions | translate}}
    </p>
  </div>
</div>
<div
  class="modal-body"
  style="overflow: auto !important"
>
  <ng-container *ngIf="termsAndConditions | async as tc">
    <div *ngFor="let term of tc">
      <a
        href="{{term.link}}"
        target="_blank"
        (click)="onLinkClick(term.link, term.location, term.version)"
        id="tcLink"
      >
        {{'label.termsAndConditionsLinkText' | translate:{x: (('tcLocation.' +
        term.location) | translate) } }}
      </a>
    </div>
  </ng-container>
</div>
<div class="modal-footer d-flex justify-content-around">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="onReject()"
    id="terms-and-condition-reject"
  >
    {{'label.rejectTAC' | translate}}
  </button>
  <button
    type="button"
    class="btn btn-tertiary"
    [disabled]="acceptDisabled"
    (click)="onAccept()"
    id="terms-and-condition-accept"
    [attr.title]="acceptDisabled ? ('label.termsAndConditionsRequired' | translate) : ''"
  >
    {{'label.acceptTAC' | translate}}
  </button>
</div>