import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'pod-ng-core';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions.modal.html',
  styles: ['.modal-body : { overflow: auto !important; height: 500px }'],
})
export class TermsAndConditionsModal {
  acceptDisabled = true;
  acceptedTermsAndConditions = '';
  acceptedVersion = '';
  acceptedLink = '';

  constructor(
    public activeModal: NgbActiveModal,
    private sessionService: SessionService
  ) {}

  termsAndConditions = this.sessionService.getTermsAndConditions();

  public onAccept() {
    this.activeModal.close({
      version: this.acceptedVersion,
      link: this.acceptedLink,
      location: this.acceptedTermsAndConditions,
    });
  }

  public onReject() {
    this.activeModal.close(false);
  }

  onLinkClick(link: string, location: string, version: string) {
    this.acceptDisabled = false;
    this.acceptedTermsAndConditions = location;
    this.acceptedLink = link;
    this.acceptedVersion = version;
  }
}
