import { Serializable } from './serializable.class';

export class Material extends Serializable {
  _id: string;
  accountId: string;
  materialNumber: string;
  materialDescription: string;
  materialGroup1: string;
  materialGroup2: string;
  materialGroup3: string;
  materialGroup4: string;
  unitOfMeasure: string;
  crossplantStatus: string;
  itemCategoryGroup: string;
  billingServiceType: string;
  salesOrganization: string;
  distributionChannel: string;
  jointAccumulator: string;
  created: string;
  modified: string;

  public getResourceName(): string {
    return 'material';
  }
}
